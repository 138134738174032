@import "../../assets/styles/media-mixin";

.board {
  padding: 20px 15px 20px;
  @include media(L) {
    padding: 20px 50px 20px;
  }

  &__title {
    margin-top: 0;
    margin-bottom: 10px;
    @include media(XL) {
      margin-bottom: 15px;
    }
  }
}
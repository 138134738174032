@import "../../../assets/styles/media-mixin";

.inner-balance {
  &__data {
    display: flex;
    flex-direction: column;
    color: var(--textColor);
  }
  &__data span {
    font-family: robotoBold, sans-serif;
    margin-top: 10px;
    font-size: 20px;
    color: var(--balanceNum);
    @include media(M) {
      margin-top: 5px;
    }
  }
}

@import "../../../../assets/styles/variables";

.step {
  &__title {
    .sticky-inner-wrapper {
      background-color: var(--greyBg);
      padding-bottom: 10px;
      z-index: 10;
    }

    span {
      text-transform: uppercase;

      &:nth-child(1) {
        color: var(--mainColor);
      }

      &:nth-child(2) {
        margin-right: 7px;
        padding-left: 14px;
        padding-right: 7px;
        border-right: 1px solid #fff;
      }

      &:nth-child(3) {
        color: var(--mainColor);
      }
    }

    &-btn {
      font-family: robotoRegular, sans-serif;
      background-color: transparent !important;
      padding-left: 0;
      padding-right: 0;
      width: 60px;
    }
  }
  &__btn-left {
    border-right: 1px solid #fff;
    border-radius: 0;
    padding-top: 0;
    padding-bottom: 0;
    height: 21px;
  }
  &__btn-right {
    color: var(--mainColor) !important;
  }
  &__disabled {
    opacity: .44;
  }
}
@import "../../../assets/styles/media-mixin";

.payment {
  &__date-button {
    margin-bottom: -21px;
    z-index: 4;
  }
  &__form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 44;
    &_calendar {
      width: 100%;
    }
  }
  &__form_submit {
    margin-top: 20px;
    margin-right: 0;
  }
}

@import "../../../../assets/styles/media-mixin";

.referral {
  &__form {
    border-top: 1px solid var(--mainColor);
    margin-top: 40px;
    padding-top: 13px;
  }
  &__group {
    display: flex;
    border-bottom: 1px solid var(--mainColor);
    margin-bottom: 13px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__name {
    display: none;
    flex-shrink: 0;
    float: left;
    line-height: 40px;
    width: 75px;
    @include media(M) {
      display: block;
    }
  }
  &__field {
    margin-right: 10px;
    width: 100%;
  }
  &__copy {
    font-family: robotoRegular, sans-serif;
    background-color: $yellow;
    border-radius: 2px;
    flex-shrink: 0;
    width: 60px;
    height: 40px;
    color: $grey-1;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
    @include media(M) {
      width: 120px;
    }
  }
  &__del {
    margin-right: 10px;
    padding-top: 5px;
    float: left;
    height: 40px;
    cursor: pointer;
  }
}

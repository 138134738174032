@import "../../../assets/styles/variables";

.field {
  margin-bottom: 13px;
  &__input {
    border: none;
    border-radius: 2px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 20px;
    width: 100%;
    height: 40px;
    background-color: var(--inputBgColor);
    &:focus {
      outline: none;
    }
  }
  input[type=range]::-ms-tooltip {
    display: none;
  }
}

.inner .field__input {
  border: 1px solid var(--inputBorder);
}
@import '../../../../assets/styles/media-mixin';

.contact {
  &__input {
    background-color: $grey-2;
    height: 70px;
    color: $grey-4;
  }
  &__textarea {
    background-color: $grey-2;
    border: none;
    margin-bottom: 5px;
    font-size: 20px;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    color: $grey-4;
    line-height: 50px;
    resize: none;
    @include media(M) {
      line-height: 70px;
    }
    &:active, &:focus, &:active {
      outline: none;
    }
  }
}
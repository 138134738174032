@import "../../../../../assets/styles/variables";

.postBack {
  &__form {
    text-align: right;
  }
  &__err {
    color: $red;
  }
  &__send {
    margin: 13px 0 25px auto;
  }
}

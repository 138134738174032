@import '../../assets/styles/media-mixin';

.custom-legend {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  padding-left: 20px;
  &__item {
    display: flex;
    justify-content: flex-end;
    &:first-child {
      margin-right: 10px;
      span {
        background-color: $yellow;
      }
    }
    &:last-child {
      margin-left: 10px;
      span {
        background-color: $red;
      }
    }
  }
  &__item span {
    display: block;
    border-radius: 2px;
    margin-top: 8px;
    margin-left: 5px;
    width: 20px;
    height: 8px;
    @include media(S) {
      width: 30px;
      height: 12px;
    }
    @include media(L) {
      width: 45px;
      height: 15px;
    }
  }
}
@import "../../assets/styles/media-mixin";

.button-default {
  background-color: $red;
  border-radius: 2px;
  padding: 9.5px 20px;
  text-transform: uppercase;
  width: 120px;
  font-size: 18px;
  text-align: center;
  line-height: initial;
  color: #fff;
  transition: .5s ease;
  cursor: pointer;
  &:hover {
    color: #fff;
    text-decoration: none;
    opacity: .8;
  }
}

.inner .button-default {
  background-color: var(--innerBtnColor);
}

.inner .button-yellow {
  background-color: $yellow;
}

.inner .button-grey {
  background-color: $grey-4;
}

.button-sm {
  font-size: 13px;
  padding: 5px 10px;
  width: unset;
}

.button-bg {
  display: inline-block;
  width: auto;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 30px;
  @include media(M) {
    padding-left: 44px;
    padding-right: 44px;
  }
  &:hover {
    color: #fff;
  }
}

.btn_disabled {
  opacity: .6 !important;
  cursor: inherit;
}
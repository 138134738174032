@import "../../../assets/styles/variables";

.sign-in {
  .modal-dialog {
    background-color: $grey-2;
    padding: 30px 35px;
    max-width: 500px;
  }
  .modal-content {
    background-color: unset;
    border: none;
  }
}

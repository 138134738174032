@import "../../../../assets/styles/variables";

.reg-modal {
  &__form {
    padding-top: 40px;
    padding-bottom: 20px;
  }
  &__field-checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      color: $yellow;
    }
  }
  &__submit {
    margin-left: auto;
    margin-right: auto;
  }
}

@import "../../../../assets/styles/media-mixin";

.stats-check {
  &__wrapper {
    margin-top: 20px;
    @include media(L) {
      margin-top: -5px;
    }
  }
  &__item {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 7px;
    max-width: 100px;
    cursor: pointer;
    color: var(--textColor);
    .checkbox__check {
      top: 0;
      right: 0;
    }
  }
  &__item > svg {
    width: 52px;
  }
  &__item > svg path:first-child {
    fill: var(--textColor)
  }
  &__item > svg path:last-child {
    fill: var(--mainColor)
  }
  &__btn {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-top: 5px;
    padding-bottom: 0;
    @include media(L) {
      justify-content: flex-end;
      padding-top: 0;
      padding-bottom: 13px;
    }
  }
}
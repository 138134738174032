@import "../../assets/styles/variables";

.links {
  &__item {
    display: flex;
    &:nth-child(odd) {
      .links__href {
        background: $dark;
      }
    }
  }
  &__name {
    color: $yellow;
    width: 250px;
    line-height: 40px;
  }
  &__href {
    padding-left: 5px;
    padding-right: 5px;
    width: 100%;
    line-height: 40px;
  }
}
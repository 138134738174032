.promo-info {
  &__item {
    margin-bottom: 15px;
    float: left;
    width: 300px;
    div {
      span:first-child {
        font-family: 'robotoRegular';
        display: inline-block;
        width: 135px;
      }
    }

  }
}
@font-face {
  font-family: "robotoRegular";
  src: url('../fonts/RobotoCondensed-Regular.ttf');
  font-display: swap;
}

@font-face {
  font-family: "robotoLight";
  src: url('../fonts/RobotoCondensed-Light.ttf');
  font-display: swap;
}

@font-face {
  font-family: "robotoBold";
  src: url('../fonts/RobotoCondensed-Bold.ttf');
  font-display: swap;
}
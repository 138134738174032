@import "../../assets/styles/media-mixin";

.testimonials {
  background-color: $grey-1;
  .container {
    position: relative;
    overflow: hidden;
  }
  &__item {
    display: block;
    position: relative;
    border: 2px solid $yellow;
    border-radius: 7px;
    padding: 15px;
    color: #fff;
    &:hover {
      color: #fff;
      text-decoration: none;
    }
    &:before {
      content: '';
      position: absolute;
      width: 2px;
      height: 30px;
      background-color: $yellow;
      bottom: -28px;
      right: 50px;
      transform: rotate(-36deg);
    }
    &:after {
      content: '';
      position: absolute;
      width: 2px;
      height: 30px;
      background-color: $yellow;
      bottom: -28px;
      right: 33px;
      transform: rotate(36deg);
    }
  }
  &__title {
    margin-top: 0;
    margin-bottom: 5px;
  }
  &__title img {
    max-width: 195px;
  }
  &__brand {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: -14px;
      right: 29px;
      width: 21px;
      height: 15px;
      background-color: $grey-1;
      transform: rotate(-54deg);
    }
    &:after {
      content: '';
      position: absolute;
      top: -14px;
      right: 39px;
      width: 21px;
      height: 15px;
      background-color: $grey-1;
      transform: rotate(54deg);
    }
  }
  &__brand a {
    text-transform: uppercase;
    color: $grey-4;
  }
  &__img {
    position: absolute;
    right: 20px;
    bottom: -100px;
    max-width: 300px;
  }
}
.testimonial__title img {
  max-width: 100%;
}
@import "../../../assets/styles/media-mixin";

.auth-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  @include media(XS){
      flex-direction: row;
      align-items: flex-end;
  }
  margin-top: 0;
  color: $grey-4;
  &__logo {
    margin-top: -10px;
    margin-right: 20px;
    max-width: 75px;
  }
  &__close {
    position: absolute;
    top: -15px;
    right: -20px;
    width: 26px;
    height: 26px;
    background-image: url("../../../assets/img/xxx.svg");
    cursor: pointer;
  }
}
@import "../../../../assets/styles/variables";

.layout {
  .checkbox__check {
    right: 25px;
    bottom: 15px;
  }
  &__item {
    position: relative;
    &-box {
      background-position: center;
      background-color: $grey-3;
      background-size: cover;
      height: 207px;
    }
  }
  &__href {
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 90px;
    height: 90px;
    opacity: 0;
    transition: .4s ease;
  }
  &__item:hover .layout__href {
    opacity: 1;
  }
}
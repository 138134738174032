@import '../../../assets/styles/media-mixin';

.height {
  height: 300px;
  background-position: center !important;
  background-size: cover !important;
  @include media(M) {
    height: 500px;
  }
  @include media(XL) {
    height: 727px;
  }
}

.wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
}

.title {
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 23px;
  font-size: 28px;
  max-width: 280px;
  @include media(M) {
    max-width: 50%;
    line-height: 35px;
    font-size: 40px;
  }
  @include media(L) {
    font-size: 60px;
    line-height: 50px;
    max-width: 55%
  }
  @include media(XL) {
    font-size: 78px;
    line-height: 61px;
    max-width: 60%
  }
}

.text {
  font-size: 13px;
  letter-spacing: 1px;
  text-transform: uppercase;
  @include media(S) {
    font-size: 15px;
  }
  @include media(L) {
    font-size: 25px;
  }
  @include media(XXL) {
    font-size: 40px;
    line-height: 53px;
  }
}
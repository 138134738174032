@import "../../../../assets/styles/media-mixin";

.l-banner {
  margin-top: 35px;
  padding-top: 35px;
  background-color: var(--preWhite);
  &__item {
    display: flex;
    flex-direction: column;
    padding-bottom: 35px;
    @include media(M) {
      flex-direction: unset;
    }
  }
  &__img-wrap {
    width: 100%;
    @include media(M) {
      margin-right: 20px;
      width: 50%;
    }
  }
  &__image {
    max-width: 100%;
  }
  &__size {
    font-family: robotoRegular, sans-serif;
    display: table;
    background-color: var(--sizeBg);
    text-transform: uppercase;
    position: relative;
    border-radius: 2px;
    padding-top: 14px;
    padding-left: 10px;
    padding-right: 60px;
    padding-bottom: 14px;
    cursor: pointer;
    span {
      font-family: robotoBold, sans-serif;
      color: $yellow;
    }
    .checkbox__check {
      margin: auto;
      height: max-content;
      top: 0;
      right: 10px;
      bottom: 0;
    }
  }
}
@import "../../assets/styles/media-mixin";

.admin {
  background-color: var(--bg);
  min-height: 100vh;
}

.inner {
  background-color: var(--innerBg);
  overflow: hidden;
  &__content {
    width: 100%;
    @include media(XL) {
      float: right;
      width: calc(100% - 251px);
    }
  }
}
@import "../../assets/styles/variables";

.customized-tooltip {
  font-family: robotoRegular, sans-serif;
  &__label {
    margin-bottom: 2px;
    background-color: $red;
    padding: 3px 10px;
  }
  &__wrapper {
    display: flex;
  }
  &__names {
    &--item {
      padding-left: 5px;
      padding-right: 5px;
    }
    &--item:first-child {
      background-color: $yellow;
      margin-bottom: 2px;
    }
    &--item:last-child {
      background-color: $red;
    }
  }
  &__values {
    width: 100%;
    &--item {
      background-color: #fff;
      padding-left: 5px;
      padding-right: 5px;
      color: $grey-2 !important;
    }
    &--item:first-child {
      margin-bottom: 2px;
    }
  }
}

@import '../../../assets/styles/variables';

.s-table {
  z-index: 1;
  color: var(--textColor);
  .ReactTable {
    .rt-thead {
      border-top: 2px solid var(--mainColor);
      border-bottom: 2px solid var(--mainColor);
      z-index: 1;

      .rt-resizable-header-content {
        line-height: 30px;
      }
    }

    .rt-th, .rt-td {
      font-family: robotoLight, sans-serif;
      background-color: transparent;
      font-size: 17px;

      .-sort-asc, .-sort-desc {
        box-shadow: none;
      }
    }
    .rt-resizable-header-content {
      text-align: left;
    }

    .rthfc-th-fixed, .rthfc-td-fixed {
      background-color: var(--preWhite);
      border-right: none;
    }

    .rt-tr-group {
      border-bottom: 1px solid var(--mainColor);
    }

    .rt-tfoot {
      border-top: 2px solid var(--mainColor);
      .rt-tr, .rt-td {
        font-family: robotoRegular, sans-serif;
      }

      .rthfc-td-fixed {
        background-color: var(--preWhite);
      }
    }
    .rt-noData {
      display: none;
    }
  }
}

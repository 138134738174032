@import "../../assets/styles/variables";

.news-item {
  background-color: $grey-1;
  &__date {
    color: $grey-4;
  }
  &__btn {
    cursor: pointer;
  }
}
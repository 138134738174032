@import "../../../assets/styles/media-mixin";

.about {
  background-color: #191a1a;
  &__item:hover &__head-text {
    border-bottom-color: $yellow;
  }
  &__img-wrap {
    text-align: center;
    img {
      max-width: 100px;
      @include media(M) {
        max-width: 220px;
      }
    }
  }
  &__head-text {
    font-family: 'robotoLight';
    margin-top: 20px;
    margin-bottom: 10px;
    padding-bottom: 5px;
    text-align: center;
    color: $grey-4;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    @include media(XL) {
      margin-top: 40px;
      margin-bottom: 20px;
    }
  }
  .active {
    border-bottom: 2px solid $yellow;
  }
}

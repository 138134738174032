@import "../../../../assets/styles/variables";

.header-auth {
  font-family: robotoRegular, sans-serif;
  letter-spacing: 0.05em;
  &__user {
    margin-right: 20px;
    height: 30px;
    color: $yellow;
    &:hover {
      color: $yellow;
    }
  }
  &__avatar {
    margin-top: -10px;
    margin-left: 10px;
  }
  &__log-out {
    height: 30px;
    text-transform: uppercase;
    cursor: pointer;
    color: $white;
    &:hover {
      color: $white;
      text-decoration: none;
    }
  }
  svg {
    margin-left: 5px;
  }
}

.admin {
  .header-auth, .mobile-auth {
    svg path {
      fill: var(--mainColor);
    }
    &__log-out, &__user {
      color: var(--textColor);
    }
  }
}
@import "../../../assets/styles/media-mixin";

.header {
  &__user {
    display: inherit;
    span {
      color: $yellow;
      padding-left: 10px;
    }
  }
}

.inner-header {
  background-color: var(--bg);
  color: var(--textColor);

  .header-auth__user {
    display: none;
  }
}

@import "../../assets/styles/variables";

.theme-toggle {
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  cursor: pointer;
  button {
    background-color: var(--mainColor);
    border-radius: 30px;
    margin-top: 38px;
    height: 27px;
    overflow: hidden;
  }
  svg {
    width: 20px;
    height: 20px;
    transition: all 0.3s linear;
    filter: var(--colorLogo);
    &:first-child {
      transform: translateY(100px);
    }
    &:nth-child(2) {
      transform: translateY(0);
    }
    path:first-child {
      fill: $white;
    }
    path:last-child {
      fill: $dark;
    }
  }

  .light {
    svg {
      &:first-child {
        transform: translateY(0);
      }
      &:nth-child(2) {
        transform: translateY(-100px);
      }
    }
  }
}
@import "../../../assets/styles/media-mixin";

.promo {
  &__filter {
    margin-top: 10px;
    @include media(XL) {
      display: flex;
      justify-content: flex-end;
      margin-top: 0;
    }
  }
  &__form {
    //width: 100%;
    .select-wrapper {
      margin-bottom: 10px;
    }
  }
}

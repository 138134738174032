@import "../../../assets/styles/variables";

.reg-modal {
  .modal-dialog {
    background-color: $grey-2;
    padding: 30px 35px;
    max-width: 640px;
  }
  .modal-content {
    background-color: unset;
    border: none;
  }
  .auth-footer__forgot {
    display: none;
  }
}

@import "../../../../assets/styles/variables";

.sign-in {
  &__form {
    padding-top: 20px;
    padding-bottom: 20px;
    .input-wrapper .input {
      color: $grey-4;
    }
  }
  &__serv-err {
    margin-top: 10px;
    margin-bottom: -15px;
    color: $red;
    font-size: 16px;
    letter-spacing: 1px;
  }
  &__remember {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
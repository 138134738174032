@import "../../../assets/styles/media-mixin";

.description {
  @include media(M) {
    margin-top: 48px;
  }
  &__title {
    margin-bottom: 10px;
    @include media(L) {
      margin-bottom: 30px;
    }
  }
  &__advantages {
    &-title {
      margin-bottom: 15px;
      color: $yellow;
      font-weight: bold;
      @include media(M) {
        margin-top: 0;
        min-height: 45px;
      }
    }

    &-list {
      margin-top: 0;
      margin-bottom: 0;
      list-style: none;
      padding-left: 0;
    }
    &-list li {
      position: relative;
      margin-bottom: 11px;
      padding-left: 40px;
      @include media(L) {
        font-size: 22px;
        line-height: 35px;
      }
    }
    &-list li:before {
      content: '';
      position: absolute;
      top: 4px;
      left: 0;
      width: 15px;
      height: 15px;
      background-image: url('../../../assets/img/chip_pick_whit.svg');
      @include media(L) {
        width: 24px;
        height: 24px;
      }
    }
  }
}

@import "../../../assets/styles/media-mixin";

.header {
  &-balance {
    justify-content: flex-end;
    align-items: center;
    text-transform: uppercase;
    div {
      &:nth-child(1), &:nth-child(2) {
       margin-right: 10px;
      }
    }
    span {
      font-family: 'robotoBold';
    }
  }
}
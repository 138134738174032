@import "../../../../assets/styles/variables";

.congrats {
  text-align: center;
  &__title {
    margin-bottom: 10px;
    color: $yellow;
  }
  &__text {
    font-family: 'robotoLight';
    margin-bottom: 10px;
  }
  &__check-mail {
    font-family: 'robotoBold';
    text-transform: uppercase;
    color: $yellow;
    letter-spacing: 1.3px;
  }
}
@import "../../assets/styles/media-mixin";
@import "~react-day-picker/lib/style.css";

.calendar-filter {
  display: flex;
  flex-direction: column;
  &__select-buttons {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 9px;
    div {
      background-color: var(--innerBtnColor);
      margin-left: 10px;
      //border-radius: 1px;
      flex-shrink: 0;
      cursor: pointer;
      font-size: 14px;
      text-transform: uppercase;
      text-align: center;
      width: 60px;
      transition: .5s ease;
      &:hover {
        opacity: .8;
      }
    }
  }
  &__from, &__to {
    position: relative;
    .text-small {
      display: none;
      width: 60px;
      font-size: 15px;
      line-height: 23px;
      text-align: right;
      @include media(M) {
        display: block;
      }
    }
  }
  &__from {
    margin-bottom: 13px;
  }
  .DayPickerInput {
    display: block;
    position: relative;
  }
  .DayPickerInput input {
    background-color: var(--inputColor);
    background-image: url("../../assets/img/cal-back.svg");
    background-position: right 10px top 5px;
    background-repeat: no-repeat;
    border: 1px solid var(--inputBorder);
    border-radius: 2px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 20px;
    height: 40px;
    line-height: 38px;
    color: #000;
    letter-spacing: 1px;
    width: 100%;
    &::placeholder {
      color: #000;
    }
    &:focus {
      outline: none;
    }
  }
  .DayPickerInput-Overlay {
    top: 5px;
    background-color: var(--inputColor);
    border-radius: 2px;
    color: #080808;
    z-index: 4;
  }
  .DayPicker-wrapper {
    padding-left: 1px;
    padding-right: 2px;
  }
  .DayPicker-Weekday {
    font-family: robotoRegular, sans-serif;
    color: #000;
  }
  .DayPicker-Day {
    padding: 1px 8.7px;
    border-radius: unset;
    color: #000;
  }
  .DayPicker-Day:hover {
    background-color: rgba(45, 36, 10, 0.10980392156862745) !important;
  }
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: rgba(45, 36, 10, 0.10980392156862745);
    color: #000;
  }
}
@import "../../../assets/styles/media-mixin";

.commission {
  &__text {
    margin-top: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 15px;
    @include media(S) {
      font-size: 18px;
    }
    @include media(L) {
      font-size: 30px;
    }
  }
  .nav-tabs {
    display: flex;
    border-bottom: none;
    @include media(M) {
      flex-direction: unset;
    }
    .nav-item {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;
      align-items: center;
      border: none;
      border-bottom: 2px solid transparent;
      color: #fff;
      width: 100%;
      max-width: 50%;
      @include media(M) {
        margin-left: unset;
        margin-right: unset;
        margin-bottom: unset;
        width: 25%;
        max-width: unset;
      }
    }
    .nav-item img {
      max-width: 80px;
      @include media(M) {
        max-width: 120px;
      }
      @include media(XL) {
        max-width: 200px;
      }
    }
    .nav-item:hover {
      border-color: transparent;
    }
    .active {
      background-color: transparent !important;
      border-bottom: 2px solid $yellow !important;
    }
  }
}

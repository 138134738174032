@import "../../../../assets/styles/media-mixin";

.contact {
  &__props {
    position: relative;
    background-image: url("../../../../assets/img/CROWN_bgcrown.svg");
    background-repeat: no-repeat;
    background-size: contain;
  }
  &__name, &__mail, &__skype {
    display: flex;
    align-items: center;
    margin-left: 0;
    margin-bottom: 20px;
    @include media(L) {
      margin-left: 40px;
    }
    a {
      margin-top: 0;
      color: #bababa;
      text-transform: unset;
      padding-left: 20px;
    }
  }
  &__name {
    margin-top: 0;
    margin-bottom: 10px;
  }
  &__skype {
    margin-bottom: 40px;
  }
}
.flag {
  display: inline-block;
  background-image: url("./../../assets/img/flags_frame.png");
  background-repeat: no-repeat;
  width: 15px;
  height: 15px;
  &_ru {
    background-position: 0 -60px;
  }
  &_en {
    background-position: 0 -75px;
  }
  &_de {
    background-position: 0 0;
  }
  &_fi {
    background-position: 0 -45px;
  }
  &_no {
    background-position: 0 -15px;
  }
  &_jp {
    background-position: 0 -30px;
  }
}

.arrow-wrap {
  position: relative;
  svg {
    position: absolute;
    top: 0;
    right: 2px;
    bottom: 0;
    margin: auto;
    transition: .2s ease;
  }
  svg path {
    fill: var(--mainColor);
  }
}
@import "../../../assets/styles/media-mixin";

.header-nav {
  justify-content: space-between;
  align-items: center;
  &__item {
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 1px ;
    color: #fff;
    @include media(XL) {
      font-size: 18px;
    }
    &:hover {
      color: $yellow;
      text-decoration: none;
    }
  }
}

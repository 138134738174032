@import "../../../assets/styles/variables";

.profile {
  &__table {
    overflow-x: scroll;
  }
  &__tabs {
    width: 100%;
    min-width: 690px;
    .nav {
      border-bottom: 1px solid var(--mainColor);
      background-color: var(--preWhite);
      &-item {
        border-radius: unset;
        border: none;
        width: 33.33%;
        height: 44px;
        font-size: 20px;
        text-align: left;
        color: var(--textColor);
      }
      .tab-item {
        position: relative;
        padding-left: 50px;
        &:before {
          content: '';
          display: block;
          position: absolute;
          left: 20px;
          top: 7px;
          width: 21px;
          height: 19px;
          background-repeat: no-repeat;
        }
      }
      &-item svg path {
        fill: var(--mainColor)
      }
      &-item.active {
        background-color: var(--mainColor);
        color: var(--bg);

      }
      &-item.active svg path {
        fill: var(--bg)
      }
    }
    .tab-content {
      background-color: var(--preWhite);
      padding: 20px;
      height: 281px;
    }
    thead {
      border-bottom: 10px solid transparent;
    }
    .table thead th {
      border-top: none;
      border-bottom: none;
      padding: 0;
    }
    .table thead th span {
      padding: 0 10px 5px 0;
      font-size: 18px;
      color: $grey-4;
    }
    .table tbody td {
      border-top: none;
      font-size: 16px;
      line-height: 30px;
      color: #fff;
      padding: 0;
    }
  }
}


@import "../../../../assets/styles/media-mixin";

.layout-branding {
  margin-top: 35px;
  padding-top: 35px;
  background-color: var(--preWhite);
  &__item {
    display: flex;
    flex-direction: column;
    padding-bottom: 35px;
    @include media(M) {
      flex-direction: unset;
    }
  }
  &__img-wrap {
    position: relative;
  }
  &__image {
    width: 100%;
  }
  .checkbox__check {
    right: 20px;
    bottom: 20px
  }
}
@import "../../../../assets/styles/media-mixin";

.step {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 10;
  &__text {
    padding-bottom: 10px;
    text-transform: uppercase;
    font-size: 13px;
    text-align: center;
    color: $grey-4;
  }
  &__text--active {
    color: var(--mainColor);
  }
  &__item div {
    display: none;
    @include media(M) {
      display: block;
    }
  }

  &__item svg rect {
    fill: var(--innerBg);
  }
  &__item svg path.colour {
    fill: var(--mainColor);
  }
  &__progress {
    position: absolute;
    left: 0;
    width: 99%;
    background: $grey-4;
    top: 19px;
    z-index: -1;
    @include media(M) {
      top: 63px;
    }
  }
  &__progress-value {
    background: var(--mainColor);
    height: 2px;
  }
}
@import "../../../assets/styles/variables";

.aside-balance {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  &__item {
    background-color: var(--preWhite);
    position: relative;
    margin-top: 20px;
    width: 130px;
    height: 50px;
    &:after {
      content: '';
      position: absolute;
      width: 60px;
      height: 60px;
      border-radius: 50px;
      background-color: var(--bg);
      top: -5px;
      left: -20px;

    }
    svg {
      width: 60px;
      position: absolute;
      top: -1px;
      left: -20px;
      z-index: 10;
    }
    svg path {
      fill: var(--mainColor);
    }
    svg path.owl {
      fill: var(--textColor);
    }
  }
  &__title {
    background-color: var(--innerBtnColor);
    padding-left: 45px;
    text-transform: uppercase;
  }
  &__data {
    font-family: robotoBold, sans-serif;
    position: absolute;
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;
    bottom: 3px;
    right: 5px;
    color: var(--textColor);
  }
}
@import "../../assets/styles/variables";

.promo-list__wrap {
  width: 100%;
  overflow-x: scroll;
  .table-responsive {
    display: inline-block;
    -webkit-overflow-scrolling: touch;
    min-width: 1100px;
  }
  .preview__button svg {
    width: 34px;
    height: 34px;
  }
  .preview__button svg path {
    fill: var(--titleName);
  }
}
.promo-list {
  color: #fff;
  thead {
    border-top: 2px solid $yellow;
    border-bottom: 2px solid $yellow;
    padding-left: 10px;
    padding-right: 10px;
  }
  thead th, tbody td {
    padding: 10px;
  }
  thead th {
    font-family: robotoRegular, sans-serif;
    border-bottom: none;
  }
  tbody td {
    border-bottom: 1px solid $yellow;
    vertical-align: middle;
  }
  tbody .yellow {
    color: $yellow;
  }
  tbody {
    .def-btn {
      font-family: robotoLight, sans-serif;
      margin-left: 10px;
      padding-left: 5px;
      padding-right: 5px;
      width: unset;
      font-size: 15px;
      height: 30px;
      line-height: unset;
      &:first-child {
        margin-left: 0;
      }
    }
    .yellow-btn {
      background-color: $yellow;
      color: #000;
    }
    .grey-btn {
      background-color: #787878;
    }
  }
  tr td:last-child{
    width:1%;
    white-space: nowrap;
  }
  &__btns {
    display: flex;
    justify-content: flex-end;
  }
}
@import "../../../assets/styles/variables";

.referral {
  border-bottom: 1px solid var(--mainColor);
  &__create-new {
    line-height: initial;
    padding-left: 5px;
    padding-right: 5px;
    width: 200px;
    text-transform: uppercase;
  }
  &__save {
    flex-shrink: 0;
    height: 40px;
  }
}

.get-code {
  border-top: 1px solid var(--mainColor);
  border-bottom: 1px solid var(--mainColor);
  &__name {
    float: left;
    line-height: 40px;
    width: 75px;
  }
  &__item {
    padding-top: 15px;
    padding-bottom: 15px;
    overflow: hidden;
    .input-wrapper {
      margin-bottom: 0;
      width: 100%;
      max-width: calc(100% - 210px);
      float: left;
    }
    .input-wrapper input {
      background-color: #fff;
    }
    button {
      float: right;
    }
    &:first-child .input-wrapper {
      max-width: calc(100% - 245px);
    }
  }
}
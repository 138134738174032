@import "../../../assets/styles/variables";

.select-wrapper {
  width: 100%;
  .select-custom {
    margin-bottom: 13px;
    font-size: 20px;
    color: hsl(0,0%,50%);
    &__control {
      border: 1px solid var(--inputBorder);
      background-color: var(--inputWhiteColor);
      border-radius: 2px;
      min-height: 40px;
      outline: none;
      box-shadow: none;
      .css-w8afj7-Input {
        margin: 0;
      }
      .css-1rhbuit-multiValue {
        max-width: 70px;
      }
    }
    &__menu {
      border-radius: 2px;
    }
    &__option--is-focused {
      background-color: silver;
      cursor: pointer;
    }
    &__option--is-selected {
      background-color: rgba(45, 36, 10, 0.109804);
    }
    &__value-container {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .yellow {
    margin-bottom: 0;
    width: 100%;
    flex-shrink: 0;
    .select-custom__control, .select-custom__menu {
      background-color: var(--inputColor);
      border: 1px solid var(--inputBorder);
      color: #000;
    }
    .select-custom__placeholder, .select-custom__indicator {
      color: #000;
    }
    .select-custom__indicator-separator {
      background-color: #000;
    }
    .select-custom__option--is-focused, .select-custom__option--is-selected {
      background-color: rgba(45, 36, 10, 0.109804);
      color: #000;
    }
  }
}

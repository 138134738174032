@import "../../../assets/styles/variables";

.promo {

  &__forms {
    background-color: var(--preWhite);
    .modal-footer {
      border-top-color: var(--mainColor);
    }
    .field__input {
      background-color: var(--tFilterBg);
      border: 1px solid var(--inputBorder);
    }
    .text {
      color: var(--textColor);
    }
    .button-default {
      background-color: var(--innerBtnColor);
    }
  }
  &__label {
    font-size: 13px;
  }
}
@import "../../../assets/styles/media-mixin";

.brands {
  padding-top: 24px;
  padding-bottom: 24px;
  @include media(M) {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .brand__item {
    text-align: center;
  }
  .brand__img {
    display: inline-block;
    position: relative;
  }
  .brand__img img {
    width: 100%;
    max-width: 200px;
    @include media(L) {
      max-width: unset;
    }
  }
  .brand__logo {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 35px;
    @include media(L) {
      height: 75px;
    }
  }
  .brand__logo-img {
    max-width: 150px;
    @include media(L) {
      max-width: 270px;
    }
  }
}

@import "../../../assets/styles/media-mixin";

.header {
  height: 60px;
  background-color: #080808;
  //margin-left: 0;
  //margin-right: 0;
  //padding-left:15px;
  //padding-right: 15px;
  @include media(M) {
    height: 100px;
  }
}

@import "../../../assets/styles/variables";

.auth-footer {
  text-align: center;
  &__forgot {
    margin-bottom: 10px;
  }
  &__forgot a {
    color: $yellow;
  }
  &__join {
    text-transform: uppercase;
    color: $red;
  }
  &__join button {
    background-color: transparent;
    text-transform: uppercase;
    text-decoration: underline;
    color: $red;
    &:hover {
      text-decoration: none;
    }
  }
}
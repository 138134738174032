@import "../../../assets/styles/media-mixin";

.inner-menu {
  display: none;
  background-color: var(--bg);
  width: 250px;
  float: left;
  @include media(XL) {
    display: block;
  }
  &__link {
    display: flex;
    flex-direction: column;
    border-bottom: 2px solid var(--mainColor);
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 30px;
    padding-bottom: 20px;
    a {
      display: inline-block;
      font-size: 16px;
      line-height: 33px;
      text-transform: uppercase;
      color: var(--textColor);
      margin-bottom: 10px;
      transition: .3s ease;
      &:hover {
        opacity: .8;
        color: var(--mainColor);
        text-decoration: none;
      }
    }
    a.active {
      color: var(--mainColor);
    }

  }
  &__symbol {
    margin-top: -5px;
    margin-right: 10px;
    width: 20px;
    filter: var(--colorLogo);
  }
  .accordion {
    button {
      color: var(--textColor);
      background-color: transparent;
      position: relative;
      padding-left: 0;
      padding-right: 0;
      text-align: initial;
      width: 100%;
    }
    .collapse, .collapsing {
      padding-left: 15px;
      a:first-child {
        padding-top: 10px;
      }
    }
  }
}
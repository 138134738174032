@import "../../../../assets/styles/variables";

.t-pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  align-items: center;
  &__text {
    margin-right: 20px;
  }
  &__controller {
    display: flex;
  }
  &__arrow {
    width: 40px;
    height: 40px;
    cursor: pointer;
    &:first-child {
      transform: rotate(90deg);
      padding-top: 10px;
    }
    &:last-child {
      transform: rotate(-90deg);
      padding-top: 10px;
    }
    svg {
      width: 100%;
    }
    svg path {
      fill: var(--mainColor)
    }
  }
  &__num {
    background-color: var(--mainColor);
    border-radius: 3px;
    text-align: center;
    color: var(--bg) !important;
    padding: 5px 15px;
    line-height: 30px;
  }
}
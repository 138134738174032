@import "../../assets/styles/media-mixin";

.figure {
  background-image: url("../../assets/img/banner_h200_1.png");
  background-size: cover;
  background-repeat: no-repeat;
  text-align: center;
  width: 100%;
  height: 100px;
  &__wrapper {
    background-color: rgba(8, 8, 8, 0.73);
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    padding-left: 15px;
    padding-right: 15px;
    align-items: center;
    height: 100%;
    @include media(XL) {
      display: none;
    }
  }
  &__brand {
    width: 100%;
    max-width: 360px;
    .header-congrats__name {
      display: none;
    }
  }
  &__balance {
    display: flex;
    margin-bottom: 10px;
  }
}

.brand__wrapper {
  padding-top: 0;
  padding-bottom: 0;
  .checkbox__check {
    top: 10px;
    right: 10px;
  }
}

.brands svg {
  width: 100%;
  max-width: 300px;
}

.brands svg path:first-child {
  fill: var(--mainColor);
}
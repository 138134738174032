@import "../../assets/styles/variables";

.forgot-password {
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .container-title {
    padding-bottom: 20px;
  }
  &__text {
    color: $grey-4;
  }
  &__input {
    background-color: #262727;
    max-width: 444px;
    color: #fff;
  }
}
@import "../../assets/styles/media-mixin";

$preloader-width: 60px;
$preloader-height: 60px;
$preloader-ball-width: 16px;
$preloader-ball-height: 16px;
$preloader-big-ball-width: 20px;
$preloader-big-ball-height: 20px;
$preloader-width-mob: 40px;
$preloader-height-mob: 40px;
$preloader-ball-width-mob: 10px;
$preloader-ball-height-mob: 10px;
$preloader-big-ball-width-mob: 12px;
$preloader-big-ball-height-mob: 12px;

.short-preloader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
  width: 100%;
  height: 100%;
  min-height: 70vh;
  &_button {
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%) scale(.4);
    width: 85px;
    height: 85px;
  }
  &__wrapper {
    position: relative;
    width: $preloader-width-mob;
    height: $preloader-height-mob;
    @include media(S) {
      width: $preloader-width;
      height: $preloader-height;
    }
  }
}

.short-preloader-btn {
  width: 20px;
  height: 20px;
  margin: 0 auto;
  .short-preloader__wrapper, .small1, .small2, .bigcon {
    width: 20px;
    height: 20px;
  }
  .ball {
    width: 6px;
    height: 6px;
  }
}

.small1 {
  transform: rotate(45deg);
  width: $preloader-width-mob;
  height: $preloader-height-mob;
  @include media(S) {
    width: $preloader-width;
    height: $preloader-height;
  }
}

.small2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: $preloader-width-mob;
  height: $preloader-height-mob;
  @include media(S) {
    width: $preloader-width;
    height: $preloader-height;
  }
}

.ball {
  position: absolute;
  width: $preloader-ball-width-mob;
  height: $preloader-ball-height-mob;
  border-radius: 50%;
  background-color: var(--mainColor);
  box-shadow: 0 2px rgba(0, 0, 0, .3);
  animation: small 2s infinite ease;
  @include media(S) {
    width: $preloader-ball-width;
    height: $preloader-ball-height;
  }
  &:first-child {
    top: 0;
    left: 0;
  }
  &:nth-child(2) {
    top: 0;
    right: 0;
  }
  &:nth-child(3) {
    right: 0;
    bottom: 0;
  }
  &:last-child {
    bottom: 0;
    left: 0;
  }
}

.bigcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  width: $preloader-width-mob;
  height: $preloader-height-mob;
  animation: 2s bigcon .25s infinite linear;
  transform-origin: center;
  @include media(S) {
    width: $preloader-width;
    height: $preloader-height;
  }
}

.big {
  width: $preloader-big-ball-width-mob;
  height: $preloader-big-ball-height-mob;
  border-radius: 15px;
  background-color: var(--mainColor);
  box-shadow: 0 0 10px  var(--mainColor),
  0 0 20px  var(--mainColor),
  0 0 30px  var(--mainColor),
  0 0 50px  var(--mainColor),
  0 0 60px  var(--mainColor);
  animation: bigball 1s infinite linear;
  @include media(S) {
    width: $preloader-big-ball-width;
    height: $preloader-big-ball-height;
  }
}

.smallball1 {
  animation-delay: -1.75s;
}

.smallball6 {
  animation-delay: -1.5s;
}

.smallball2 {
  animation-delay: -1.25s;
}

.smallball7 {
  animation-delay: -1s;
}

.smallball3 {
  animation-delay: -.75s;
}

.smallball8 {
  animation-delay: -.5s;
}

.smallball4 {
  animation-delay: -.25s;
}

.smallball5 {
  animation-delay: -0s;
}

@keyframes bigcon {
  0% {
    transform-origin: center;
    transform: translate(-50%, -50%) rotate(45deg);
  }
  100% {
    transform-origin: center;
    transform: translate(-50%, -50%) rotate(405deg);
  }
}
@keyframes small {
  0% {
    transform: scale(1);
    background-color:  var(--mainColor);
  }
  10% {
    transform: scale(1.3);
    background-color: $yellow-shine;
  }
  15% {
    transform: scale(1);
  }
  25% {
    transform: scale(1);
    background-color:  var(--mainColor);
  }
  100% {
    transform: scale(1);
    background-color:  var(--mainColor);
  }
}

@import "../../../assets/styles/variables";

.mobile-menu {
  background: #080808;
  &__wrap {
    padding: 50px 20px;
  }
  &__links {
    display: flex;
    flex-direction: column;
    border-top: 2px solid $yellow;
    border-bottom: 2px solid $yellow;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    max-width: 350px;
    text-transform: uppercase;
  }
  &__item {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    color: #fff;
    width: 250px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__item:hover {
    color: $yellow;
    text-decoration: none;
  }
}

.mobile-auth {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  //width: 350px;
  & svg {
    margin-left: 5px;
  }
}

.admin .mobile-menu {
  background-color: var(--bg);
  &__item {
    color: var(--textColor);
  }
  &__item:hover {
    color: var(--mainColor);
  }
  &__item .active {
    background-color: var(--mainColor);
  }
  &__links {
    border-top: 2px solid var(--mainColor);
    border-bottom: 2px solid var(--mainColor);
  }
}
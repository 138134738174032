@import "../../../../assets/styles/variables";

.t-filter {
  margin-top: 20px;
  margin-bottom: 30px;
  button {
    font-family: robotoLight, sans-serif;
    font-size: 20px;
    width: 100%;
    background-color: var(--inputWhiteColor);
    border: 1px solid var(--inputBorder);
  }
  &__option {
    background-color: transparent !important;
    border-radius: 4px;
    position: relative;
    padding-left: 40px !important;
    font-size: 18px !important;
    color: #151515 !important;
    &:before {
      content: '';
      display: block;
      border: 2px solid var(--mainColor);
      border-radius: 3px;
      position: absolute;
      left: 11px;
      top: 13px;
      width: 17px;
      height: 17px;
    }
    &:after {
      content: '';
      display: block;
      border-radius: 50%;
      background-color: var(--mainColor);
      position: absolute;
      left: 15px;
      top: 17px;
      width: 9px;
      height: 9px;
    }
    &:hover {
      background-color: transparent !important;
      cursor: pointer;
    }
    &--is-focused {
      background-color: transparent;
    }
    &--is-selected {
      color: $grey-4 !important;
      &:after {
        display: none;
      }
    }
    input {
      position: absolute;
      visibility: hidden;
    }
  }
  &__menu {
    background-color: var(--tFilterBg);
    border: 1px solid var(--inputBorder);
    border-radius: 2px;
  }
}
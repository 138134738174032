@import "../../../assets/styles/variables";

.preview {
  &__img {
    width: 100%;
  }
  &__naked-link {
    color: #fff;
    &:hover {
      color: #fff;
    }
  }
  &__button {
    cursor: pointer;
  }
  .modal-content {
    background-color: var(--preWhite);
    color: var(--textColor);
    .text {
      color: var(--textColor);
    }
    .button-default {
      background-color: var(--innerBtnColor);
    }
  }
  .modal-footer {
    border-top-color: var(--mainColor);
  }
}
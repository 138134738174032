@import "../../assets/styles/variables";

.sub-affiliate {
  &__field {
    span:first-child {
      display: inline-block;
      width: 180px;
    }
    span:nth-child(2) {
      background: var(--mainColor);
      border: 1px solid var(--mainColor);
      border-radius: 2px;
      padding: 10px 30px;
      height: 40px;
      line-height: 40px;
      color: var(--textColorInvert);
    }
  }
  &__input {
    background-color: var(--inputWhiteColor);
    border-radius: 2px;
    border: 1px solid var(--inputBorder);
    padding-left: 10px;
    padding-right: 10px;
    width: 75%;
    font-size: 20px;
    height: 40px;
    &:focus {
      outline: none;
    }
  }
}
@import "../../../../assets/styles/media-mixin";

.header {
  &-congrats {
    justify-content: flex-end;
    align-items: center;
    &__name {
      margin-right: 15px;
    }
    &__name span {
      color: var(--titleName);
    }
    .select-custom {
      @include media(XL) {
        max-width: 260px;
      }
    }
  }
}


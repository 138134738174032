@import "../../assets/styles/media-mixin";

.footer {
  &__navigation {
    display: flex;
    justify-content: center;
    border-bottom: 2px solid $yellow;
    border-top: 2px solid transparent;
  }
  &__link {
    color: $white;
    padding-left: 20px;
    padding-right: 20px;
    line-height: 70px;
    text-transform: uppercase;
    &:hover {
      color: $yellow;
      text-decoration: none;
    }
  }
  &__copy-text {
    display: flex;
    align-items: center;
    opacity: .4;
  }
  &__logo-wrap {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    @include media(M) {
      margin-bottom: 0;
      align-items: flex-end;
    }
    span {
      font-size: 30px;
      line-height: 35px;
      color: $grey-4;
    }
  }
  svg {
    width: 60px;
  }
  &__copy {
    margin-top: 20px;
    text-align: center;
  }
}

.admin .footer {
  position: relative;
  @include media(XL) {
    margin-left: 250px;
  }
  &:after {
    content: '';
    background-image: url('../../assets/img/BG_crown_light_2.png');
    background-color: var(--bg);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: var(--opacityLogo);
    z-index: -1;
  }
  .footer__navigation {
    border-bottom: 2px solid var(--mainColor);
  }
  .footer__link {
    color: var(--textColor);
    &:hover {
      color: var(--mainColor);
    }
  }
  .row {
    color: var(--textColor);
  }
  .footer__copy {
    color: var(--dark);
  }
}

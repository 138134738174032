@import "../../../../assets/styles/variables";

.header-auth__btn {
  padding-left: 25px;
  padding-right: 25px;
  font-size: 18px;
  line-height: 35px;
  height: 40px;
  border-radius: 2px;

  &:first-child {
    background-color: $yellow;
  }

  &:last-child {
    margin-left: 15px;
    background-color: #000;
    border: 1px solid $yellow;
  }
}

@import "../../assets/styles/media-mixin";

.news {
  &__date {
    text-transform: uppercase;
    color: $grey-4;
  }
  &__title {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  &__img {
    background-size: cover;
    margin-left: auto;
    margin-right: auto;
    max-width: 320px;
    height: 238px;;
    background-color: #474242;
    overflow: hidden;
    @include media(M) {
      max-width: 100%;
    }
  }
  &__text {
    margin-top: 15px;
    @include media(L) {
      margin-top: 40px;
    }
  }
}

.news-all {
  background-color: $grey-1;
}
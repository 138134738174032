@import "fonts";
@import "media-mixin";
@import "~bootstrap/scss/bootstrap";

body {
  margin: 0;
  font-family: robotoLight, sans-serif;
  background-color: $dark;
  color: $white;
}

a {
  text-decoration: none;
  &:active, &:focus, &:active {
    outline: none;
  }
}

.text {
  font-size: 15px;
  line-height: 21px;
  letter-spacing: 1px;
  @include media(S) {
    font-size: 18px;
    line-height: 28px;
  }
  @include media(L) {
    font-size: 22px;
    line-height: 30px;
  }
}

.text-small {
  font-size: 13px;
  line-height: 15px;
  letter-spacing: 1px;
  @include media(L) {
    font-size: 18px;
    line-height: 23px;
  }
}

.text-head {
  font-family: 'robotoRegular', sans-serif;
  margin-top: 20px;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  @include media(L) {
    font-size: 30px;
  }
}

.inner .text, .inner .text-small, .inner .text-head {
  color: var(--textColor);
}

button {
  font-family: 'robotoLight', sans-serif;
  border: none;
  color: #fff;
  cursor: pointer;
  &:focus,
  &:active {
    outline: none;
  }
}

.more_btn {
  font-size: 20px;
  text-decoration-line: underline;
  text-transform: uppercase;
  color: $grey-4;
  @include media(M) {
    font-size: 30px;
    line-height: 45px;
  }
  &:hover {
    color: $grey-4;
    text-decoration-line: none;
  }
}

.container-title {
  font-family: 'robotoRegular', sans-serif;
  font-size: 30px;
  padding-bottom: 30px;
  text-transform: uppercase;
  @include media(XL) {
    font-size: 45px;
    padding-bottom: 50px;
  }
}

.bg_crown {
  position: relative;
  &:after {
    content: '';
    background-image: url('../../assets/img/BG_crown_light_2.png');
    background-color: $dark;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
  }
}

.fade-exit {

}

.fade-exit-active, .fade-inner-exit-active {
  display: none !important;
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 444ms ease-in;
}

.fade-inner-enter {
  opacity: 0;
  z-index: 1;
}

.fade-inner-enter.fade-inner-enter-active {
  opacity: 1;
  transition: opacity 244ms ease-in;
}
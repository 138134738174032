@import "../../../assets/styles/media-mixin";

.header-logo {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  svg {
    height: 60%;
    width: 50px;
    @include media(S) {
      width: unset;
    }
  }
}

.inner-header .header-logo svg path {
  fill: var(--textColor)
}

.inner-header .header-logo svg path:last-child {
  fill: $yellow
}

@import "../../assets/styles/media-mixin";

.profile {
  &__wrapper {
    border-top: 2px solid var(--mainColor);
    border-bottom: 2px solid var(--mainColor);
    padding-top: 10px;
    padding-bottom: 20px;
  }
  &__board {
    padding-bottom: 0;
    @include media(XL) {
      padding-bottom: 20px;
    }
  }
  &__title {
    font-size: 26px;
    color: var(--textColor);
    text-transform: uppercase;
  }
}
@import "../../../assets/styles/media-mixin";

.header-toggle {
  text-align: right;
  &__wrap {
    display: inline-block;
    background-color: transparent;
    margin-top: 15px;
    padding: 0;
    border-radius: 0;
    @include media(M) {
      margin-top: 35px;
    }
    span {
      display: block;
      width: 40px;
      height: 4px;
      transition: all 0.3s ease-in-out;
      background-color: var(--textColor);
      &:nth-child(2) {
        margin-top: 5px;
        margin-bottom: 5px;
      }
      &:nth-child(3) {
        margin-bottom: 5px;
      }
    }
    &.activated {
      span:first-child {
        transform: translateY(13px) rotate(-45deg);
      }
      span:nth-child(2) {
        transform: translateY(4px) rotate(45deg);
      }
      span:nth-child(3) {
        transform: translateY(-5px) rotate(45deg);
      }
      span:last-child {
        transform: translateY(-14px) rotate(-45deg);
      }
    }
  }
}

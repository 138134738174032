$yellow_shine: rgba(249, 201, 66, 0.51);
$yellow: rgb(249, 201, 66);
$red: #d82d28;
$grey-1: #191a1a;
$grey-2: #262727;
$grey-3: #373737;
$grey-4: #b8b8b8;
$dark: #080808;
$white: #fff;
$white-2: #F1F1F1;
$blue: #3498DB;

:root {
  --bg: #080808;
  --textColor: #{$white};
  --textColorInvert: #{$dark};
  --greyBg: #{$grey-1};
  --bgOpacityCrown: 1;
  --colorLogo: unset;
  --opacityLogo: unset;
  --mainColor: #{$yellow};
  --preWhite: #{$grey-2};
  --innerBtnColor: #{$red};
  --titleName: #{$grey-4};
  --inputColor: #{$yellow};
  --inputBorder: none;
  --inputWhiteColor: #{$white};
  --balanceNum: #{$yellow};
  --sizeBg: $grey-3;
  --tabIcoColor: #{$dark};
  --tFilterBg: #{$white};
  --inputBgColor: #fff;
  --innerBg: #{$grey-1};
}

html[theme='light'] {
  --bg: #fff;
  --textColor: #{$dark};
  --textColorInvert: #{$white};
  --greyBg: #efefef;
  --bgOpacityCrown: .3;
  --colorLogo: invert(1);
  --opacityLogo: .2;
  --mainColor: #{$blue};
  --preWhite: #{$white-2};
  --innerBtnColor: #{$blue};
  --titleName: #787878;
  --inputColor: #D1E6EB;
  --inputBorder: #C4C4C4;
  --inputWhiteColor: #D1E6EB;
  --balanceNum: #4F4F4F;
  --sizeBg: #e8e8e8;
  --tabIcoColor: #{$white};
  --tFilterBg: #D1E6EB;
  --inputBgColor: #{$white-2};
  --innerBg: #{$white};
}

$XS: 400px;
$S: 576px;
$M: 768px; // 0- 768 - mobile
$L: 992px; // 768- 992 - tablet
$XL: 1200px; // 992 - infinity - desktop
$XXL: 1450px;

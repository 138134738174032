@import '../../../assets/styles/media-mixin';

.promo-type {
  &__wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    .col {
      flex-grow: 0;
    }
    .checkbox__check {
      top: -30px;
      right: -5px;
      @include media(M) {
        right: -10px;
      }
    }
  }
  &__item {
    position: relative;
    text-transform: uppercase;
    width: 138px;
  }
  &__item img {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  &__item> svg {
    width: 100px;
  }
  &__item> svg path {
    fill: var(--mainColor);
  }
}
@import '../../../../assets/styles/media-mixin';

.payment {
  &__input {
    &-info {
      background-color: $grey-4;
    }
  }
  &__field {
    width: 100%;
  }
  &__form-btn {
    margin-top: 6px;
    @include media(M) {
      margin-top: 30px;
    }
  }
}
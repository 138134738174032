@import "../../../../assets/styles/variables";

.link-steps {
  .prev {
    background-color: transparent;
    border: 1px solid var(--innerBtnColor);
    margin-right: 15px;
    color: var(--textColor);
  }
  .disabled {
    opacity: .4;
  }
}
@import "../../../assets/styles/variables";

.custom-checkbox {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: -2px;
  width: 18px;
  height: 18px;
  border: 1px solid $yellow;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  input {
    visibility: hidden;
  }
  label {
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 4px;
    transition: all .5s ease;
    cursor: pointer;
    position: absolute;
    top: 3px;
    left: 3px;
    z-index: 1;
  }
}

.custom-checkbox input[type=checkbox]:checked + label {
  background: $yellow;
}
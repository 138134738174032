@import "../../../../assets/styles/media-mixin";

.postBack {
  &__name {
    float: left;
    line-height: 40px;
    width: 75px;
  }
  &__title {
    padding-top: 15px;
    padding-bottom: 15px;
    line-height: 40px;
    span {
      display: inline-block;
      text-transform: uppercase;
      color: $yellow;
      width: 140px;
      cursor: pointer;
      @include media(M) {
        width: 190px;
      }
    }
    a {
      margin-left: 20px;
      color: #fff;
      text-decoration: underline;
      line-height: 40px;
      &:hover {
        text-decoration: none;
      }
    }
  }
}